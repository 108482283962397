header {
    background-color: black;
    padding-top: 2%;

    display: grid; 
    grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1.5fr 1fr 0.5fr; 
    grid-template-rows: 1fr; 
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
}

a#LogoLink {
    grid-area: 1 / 2 / 2 / 4;
    margin-bottom: 10%;
}

a.HeaderLink {
    color: white;
    font-size: 1vw;
    text-decoration: none;
    font-weight: 600;
    text-align: center;

    margin-right: 24px;
}
a.ActiveHeaderLink {
    color: #86BC25;
     border-bottom: #86BC25 4px solid;
    font-size: 1vw;
    text-decoration: none;
    font-weight: 600;
    text-align: center;

    margin-right: 24px;
}
 a.HeaderLink:hover {
     color: #86BC25;
     border-bottom: #86BC25 4px solid;
 }

 #LastUpdateLabel {
    grid-area: 1 / 7 / 2 / 9;
    color: white;
    font-size: 1vw;
 }

 #IndicatorsLink {
    grid-area: 1 / 9 / 2 / 10;
 }

 #HedgingStrategyLink {
    grid-area: 1 / 10 / 2 / 11;
 }

 #StatusHedgeLink {
    grid-area: 1 / 11 / 2 / 12;
 }