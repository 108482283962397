div#HedgingStrategy {
    /*display: grid; 
    grid-template-columns: 1fr; 
    grid-template-rows: 1fr 9fr; 
    grid-column-gap: 0px;
    grid-row-gap: 0px;*/
}

table.HedgingPriceTable td {
    padding: 15px;
}

div#HedgingStrategy button {
    margin: auto;
    width: 100%;
}