.EnergyTabs {
}

.EnergyTabList {

}

.EnergyTab {
    background-color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    padding-left: 42px;
    padding-right: 42px;
    padding-top: 10px;
    padding-bottom: 11px;
    margin-right: 2px;
}

.SelectedEnergyTab {
    background-color: #FAFAFA;
    color: #86BC25;
    border-bottom: #86BC25 solid 3px;
}

.EnergyPanel {
    background-color: #FAFAFA;
}