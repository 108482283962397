div.AdminInterface {
}

div#AdminInterfaceContainer {
    background: white;
    margin: 5px;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 20px;
}

table.UserManagementTable td {
    border: 1px black solid;
}