footer {
    background-color: #FAFAFA;

    display: grid; 
    grid-template-columns: 0.5fr 1fr 1.5fr 1fr 0.5fr 0.5fr 1.5fr 1fr 1fr 1fr 1fr 0.5fr; 
    grid-template-rows: 1fr; 
    grid-column-gap: 0px;
    grid-row-gap: 0px; 

    position: fixed;
    bottom: 0;
    height: 25px;
    width: 100%;

    padding-top: 1%;
    padding-bottom: 1%;
}

footer a {
    color: #75787B;
    font-size: 1vw;
    text-decoration: none;
}

footer a:hover {
    color: #86BC25;
}

a#ImprintLink {
    grid-area: 1 / 2 / 2 / 3;
}

a#TermsAndConditionsLinks {
    grid-area: 1 / 3 / 2 / 4;
}

a#AdminLink {
    grid-area: 1 / 4 / 2 / 5;
}

button#LogoutButton {
    grid-area: 1 / 5 / 2 / 5;
    background-color: transparent;
    border: none;
    color: #75787B;
    font-size: 1vw;
    text-decoration: none;
}

button#LogoutButton:hover {
    color: #86BC25;
    cursor: pointer;
}