.CommodityOverviews {
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: 1fr; 
    grid-column-gap: 40px;
    grid-row-gap: 0px; 
}

.CommodityOverview {
    

    background-color: #FAFAFA;
    border-radius: 5px;
    box-shadow: 1px 0px 4px #808080;
}

.OverviewTitle {
    padding: 10px;
}

.OverviewTitle h4 {
    font-size: 14px;
    color: #75787B;
}
 .OverviewTitle a {
     font-size: 40px;
     font-weight: bolder;
     color: black;
     text-decoration: none;
 }
 .OverviewTitle a:hover {
     text-decoration: underline;
 }

 .ActualPrice {
    background-color: #FFFFFF;

    padding: 10px;
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr; 
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
 }

 .ActualPrice h4 {
     font-size: 17px;
     color: #75787B;
     grid-area: 1 / 1 / 2 / 2;
 }

 .ActualPrice h3 {
     font-size: 26px;
     font-weight: bold;
     grid-area: 2 / 1 / 3 / 2;
 }

 .ActualPrice div {
     text-align: right;
     grid-area: 2 / 2 / 3 / 3;
 }

 .ActualPrice img {
     width: 105;
     height: 40px;
 }

 .AlertContainer {
     font-size: 13px;
     background-color: #FFFFFF;

     padding-top: 24px;
     padding-bottom: 24px;
     padding-left: 22px;
     padding-right: 26px;
 }

.ActionAlertBox {
     background-color: #F9252511;
     color: #F92525;
 }

 .HoldAlertBox {
    background-color: #75787B26;
    color: #75787B;
}

.AttentionAlertBox {
    background-color: #FEC16313;
    color: #FEC163;
}

.OverviewStatusHedge {
    background-color: #FFFFFF;
    padding: 16px;
}

h4.OverviewSubtitle {
    font-size: 17px;
    color: #75787B;
}

.OverviewIndicator {
    background-color: #FFFFFF;
    padding: 16px;
}

.OverviewIndicator hr {
    border-top: 3px solid #FAFAFA;
}

.OverviewIndicatorArrows {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr; 
    grid-column-gap: 3px;
    grid-row-gap: 3px; 

    background-color: #FAFAFA;
}

.IndicatorArrowContainer {
    padding: 40px;

    background-color: #FFFFFF;
}

.IndicatorArrow {
    display: block;
    margin: auto;
}

.IndicatorArrowLabel {
    margin-top: 10px;
    text-align: center;
}

.IndicatorTable {
    display: grid; 
    grid-template-columns: 1fr; 
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr; 
    grid-column-gap: 0px;
    grid-row-gap: 15px; 
    margin-top: 15px;
}

.IndicatorTableRow {
    display: grid;
    grid-template-columns: 1fr 4fr 4fr; 
    grid-template-rows: 1fr;
    grid-row-gap: 0px;
    grid-column-gap: 0px;
}

.IndicatorTableRow p {
    font-size: 13px;
    color: #4D4F5C;
}

.IndicatorTableRow:hover p {
    font-weight: bold;
}

.IndicatorTableRow:hover .circle {
    border: 3px solid black;
}

.IndicatorTableRowValue {
    text-align: right;
}

.circle {
    display: inline-block;
    margin-right: 10px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 3px solid #E8EAE9;
    position: relative;
}

.first-circle {
    border: 3px solid #C7C7C5;
}

.circle:after {
    content: '••';
    color: #E8EAE9;
    position: absolute;
    top: 160%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    font-size: 15px; 
    letter-spacing: 4px;
    margin-top: 2px;
  }