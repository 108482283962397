.LoginMask {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 5px; 

    width: 20%;
    margin: auto;
    padding: 1vw;

    background-color: #FAFAFA;
    border-radius: 5px;
    box-shadow: 1px 0px 4px #808080;
}

#LoginMaskError {
    border: #F92525 1px solid;
    border-radius: 5px;
    background-color: #F9252511;
    color: #F92525;
}