div#CommodityIndicators {
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr; 
    grid-column-gap: 16px;
    grid-row-gap: 16px;
}

div.IndicatorPanel {
    background-color: #FFFFFF;
    padding: 16px;

    display: grid; 
    grid-template-columns: 1fr; 
    grid-template-rows: 1fr 4fr; 
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
}

div.IndicatorPanelHeader {
    display: grid;
    grid-template-columns: 1fr 7fr 1fr; 
    grid-template-rows: 1fr;
}

div.IndicatorPanelHeader a {
    font-size: 32px;
    font-weight: normal;
    text-decoration: none;
    color: #4D4F5C;
}

div.IndicatorInfoPanel {
    background-color: #FFFFFF;
    padding: 16px;

    display: grid; 
    grid-template-columns: 1fr; 
    grid-template-rows: 1fr 3fr; 
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
}

div.IndicatorInfoPanelHeader {
    display: grid;
    grid-template-columns: 1fr 7fr 1fr; 
    grid-template-rows: 1fr;
}

div.IndicatorInfoPanelHeader a {
    font-size: 32px;
    font-weight: normal;
    text-decoration: none;
    color: #4D4F5C;
}

div.IndicatorInfoPanelBody {
    padding: 16px;
}