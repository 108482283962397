div#StatusHedge {
}

div#StatusHedge hr {
    margin: 10px;
}

.ArrowBar {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
}

.ArrowContainer {
    width: 100%;
}

.ArrowContainer h4 {
    width: 100%;
    text-align: center;
}

.StatusHedgeIndicatorArrow {
    width: 100%;
    margin: 0 auto;
}

div.StatusHedgeCountrySelectContainer {
    margin: auto;
    width: fit-content;
}

div.StatusHedgeTables {
}

.StatusHedgeTableContainer h3 {
    text-align: center;
}

table.StatusHedgeTable {
    border-collapse: collapse;
    margin: auto;
}

table.StatusHedgeTable th {
    font-weight: bold;
    text-decoration: underline;
}

table.StatusHedgeTable td, th {
    border: 1px solid black;
}

table.StatusHedgeTable td, th {
    text-align: center;
    padding: 3px;
    width: 16.6%;
}

span.flag-span {
    margin-right: 5px;
}

div#statusHedgeTableAlert {
    border: #75787B 1px solid;
    border-radius: 5px;
    background-color: #75787B26;
    color: #75787B;
    text-align: center;

    width: fit-content;
    padding: 10px;
    width: 50%;
    margin: 10px auto 10px auto;
}