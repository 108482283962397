@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
* {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
}

body {
    background-color: #E8EAE9;
}

.App {
    background-color: #E8EAE9;
}

main {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 80px;

    display: grid; 
    grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr; 
    grid-template-rows: 0.05fr 1fr; 
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
}

h1#ContentTitle {
    grid-area: 1 / 2 / 2 / 12;
}

#Content {
    grid-area: 2 / 2 / 3 / 12;
}
.LoginMask {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 5px; 

    width: 20%;
    margin: auto;
    padding: 1vw;

    background-color: #FAFAFA;
    border-radius: 5px;
    box-shadow: 1px 0px 4px #808080;
}

#LoginMaskError {
    border: #F92525 1px solid;
    border-radius: 5px;
    background-color: #F9252511;
    color: #F92525;
}
header {
    background-color: black;
    padding-top: 2%;

    display: grid; 
    grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1.5fr 1fr 0.5fr; 
    grid-template-rows: 1fr; 
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
}

a#LogoLink {
    grid-area: 1 / 2 / 2 / 4;
    margin-bottom: 10%;
}

a.HeaderLink {
    color: white;
    font-size: 1vw;
    text-decoration: none;
    font-weight: 600;
    text-align: center;

    margin-right: 24px;
}
a.ActiveHeaderLink {
    color: #86BC25;
     border-bottom: #86BC25 4px solid;
    font-size: 1vw;
    text-decoration: none;
    font-weight: 600;
    text-align: center;

    margin-right: 24px;
}
 a.HeaderLink:hover {
     color: #86BC25;
     border-bottom: #86BC25 4px solid;
 }

 #LastUpdateLabel {
    grid-area: 1 / 7 / 2 / 9;
    color: white;
    font-size: 1vw;
 }

 #IndicatorsLink {
    grid-area: 1 / 9 / 2 / 10;
 }

 #HedgingStrategyLink {
    grid-area: 1 / 10 / 2 / 11;
 }

 #StatusHedgeLink {
    grid-area: 1 / 11 / 2 / 12;
 }
footer {
    background-color: #FAFAFA;

    display: grid; 
    grid-template-columns: 0.5fr 1fr 1.5fr 1fr 0.5fr 0.5fr 1.5fr 1fr 1fr 1fr 1fr 0.5fr; 
    grid-template-rows: 1fr; 
    grid-column-gap: 0px;
    grid-row-gap: 0px; 

    position: fixed;
    bottom: 0;
    height: 25px;
    width: 100%;

    padding-top: 1%;
    padding-bottom: 1%;
}

footer a {
    color: #75787B;
    font-size: 1vw;
    text-decoration: none;
}

footer a:hover {
    color: #86BC25;
}

a#ImprintLink {
    grid-area: 1 / 2 / 2 / 3;
}

a#TermsAndConditionsLinks {
    grid-area: 1 / 3 / 2 / 4;
}

a#AdminLink {
    grid-area: 1 / 4 / 2 / 5;
}

button#LogoutButton {
    grid-area: 1 / 5 / 2 / 5;
    background-color: transparent;
    border: none;
    color: #75787B;
    font-size: 1vw;
    text-decoration: none;
}

button#LogoutButton:hover {
    color: #86BC25;
    cursor: pointer;
}
.CommodityOverviews {
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: 1fr; 
    grid-column-gap: 40px;
    grid-row-gap: 0px; 
}

.CommodityOverview {
    

    background-color: #FAFAFA;
    border-radius: 5px;
    box-shadow: 1px 0px 4px #808080;
}

.OverviewTitle {
    padding: 10px;
}

.OverviewTitle h4 {
    font-size: 14px;
    color: #75787B;
}
 .OverviewTitle a {
     font-size: 40px;
     font-weight: bolder;
     color: black;
     text-decoration: none;
 }
 .OverviewTitle a:hover {
     text-decoration: underline;
 }

 .ActualPrice {
    background-color: #FFFFFF;

    padding: 10px;
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr; 
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
 }

 .ActualPrice h4 {
     font-size: 17px;
     color: #75787B;
     grid-area: 1 / 1 / 2 / 2;
 }

 .ActualPrice h3 {
     font-size: 26px;
     font-weight: bold;
     grid-area: 2 / 1 / 3 / 2;
 }

 .ActualPrice div {
     text-align: right;
     grid-area: 2 / 2 / 3 / 3;
 }

 .ActualPrice img {
     width: 105;
     height: 40px;
 }

 .AlertContainer {
     font-size: 13px;
     background-color: #FFFFFF;

     padding-top: 24px;
     padding-bottom: 24px;
     padding-left: 22px;
     padding-right: 26px;
 }

.ActionAlertBox {
     background-color: #F9252511;
     color: #F92525;
 }

 .HoldAlertBox {
    background-color: #75787B26;
    color: #75787B;
}

.AttentionAlertBox {
    background-color: #FEC16313;
    color: #FEC163;
}

.OverviewStatusHedge {
    background-color: #FFFFFF;
    padding: 16px;
}

h4.OverviewSubtitle {
    font-size: 17px;
    color: #75787B;
}

.OverviewIndicator {
    background-color: #FFFFFF;
    padding: 16px;
}

.OverviewIndicator hr {
    border-top: 3px solid #FAFAFA;
}

.OverviewIndicatorArrows {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr; 
    grid-column-gap: 3px;
    grid-row-gap: 3px; 

    background-color: #FAFAFA;
}

.IndicatorArrowContainer {
    padding: 40px;

    background-color: #FFFFFF;
}

.IndicatorArrow {
    display: block;
    margin: auto;
}

.IndicatorArrowLabel {
    margin-top: 10px;
    text-align: center;
}

.IndicatorTable {
    display: grid; 
    grid-template-columns: 1fr; 
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr; 
    grid-column-gap: 0px;
    grid-row-gap: 15px; 
    margin-top: 15px;
}

.IndicatorTableRow {
    display: grid;
    grid-template-columns: 1fr 4fr 4fr; 
    grid-template-rows: 1fr;
    grid-row-gap: 0px;
    grid-column-gap: 0px;
}

.IndicatorTableRow p {
    font-size: 13px;
    color: #4D4F5C;
}

.IndicatorTableRow:hover p {
    font-weight: bold;
}

.IndicatorTableRow:hover .circle {
    border: 3px solid black;
}

.IndicatorTableRowValue {
    text-align: right;
}

.circle {
    display: inline-block;
    margin-right: 10px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 3px solid #E8EAE9;
    position: relative;
}

.first-circle {
    border: 3px solid #C7C7C5;
}

.circle:after {
    content: '••';
    color: #E8EAE9;
    position: absolute;
    top: 160%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(90deg);
            transform: translate(-50%, -50%) rotate(90deg);
    font-size: 15px; 
    letter-spacing: 4px;
    margin-top: 2px;
  }
.EnergyTabs {
}

.EnergyTabList {

}

.EnergyTab {
    background-color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    padding-left: 42px;
    padding-right: 42px;
    padding-top: 10px;
    padding-bottom: 11px;
    margin-right: 2px;
}

.SelectedEnergyTab {
    background-color: #FAFAFA;
    color: #86BC25;
    border-bottom: #86BC25 solid 3px;
}

.EnergyPanel {
    background-color: #FAFAFA;
}
div#CommodityIndicators {
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr; 
    grid-column-gap: 16px;
    grid-row-gap: 16px;
}

div.IndicatorPanel {
    background-color: #FFFFFF;
    padding: 16px;

    display: grid; 
    grid-template-columns: 1fr; 
    grid-template-rows: 1fr 4fr; 
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
}

div.IndicatorPanelHeader {
    display: grid;
    grid-template-columns: 1fr 7fr 1fr; 
    grid-template-rows: 1fr;
}

div.IndicatorPanelHeader a {
    font-size: 32px;
    font-weight: normal;
    text-decoration: none;
    color: #4D4F5C;
}

div.IndicatorInfoPanel {
    background-color: #FFFFFF;
    padding: 16px;

    display: grid; 
    grid-template-columns: 1fr; 
    grid-template-rows: 1fr 3fr; 
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
}

div.IndicatorInfoPanelHeader {
    display: grid;
    grid-template-columns: 1fr 7fr 1fr; 
    grid-template-rows: 1fr;
}

div.IndicatorInfoPanelHeader a {
    font-size: 32px;
    font-weight: normal;
    text-decoration: none;
    color: #4D4F5C;
}

div.IndicatorInfoPanelBody {
    padding: 16px;
}
div#HedgingStrategy {
    /*display: grid; 
    grid-template-columns: 1fr; 
    grid-template-rows: 1fr 9fr; 
    grid-column-gap: 0px;
    grid-row-gap: 0px;*/
}

table.HedgingPriceTable td {
    padding: 15px;
}

div#HedgingStrategy button {
    margin: auto;
    width: 100%;
}
div#StatusHedge {
}

div#StatusHedge hr {
    margin: 10px;
}

.ArrowBar {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
}

.ArrowContainer {
    width: 100%;
}

.ArrowContainer h4 {
    width: 100%;
    text-align: center;
}

.StatusHedgeIndicatorArrow {
    width: 100%;
    margin: 0 auto;
}

div.StatusHedgeCountrySelectContainer {
    margin: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

div.StatusHedgeTables {
}

.StatusHedgeTableContainer h3 {
    text-align: center;
}

table.StatusHedgeTable {
    border-collapse: collapse;
    margin: auto;
}

table.StatusHedgeTable th {
    font-weight: bold;
    text-decoration: underline;
}

table.StatusHedgeTable td, th {
    border: 1px solid black;
}

table.StatusHedgeTable td, th {
    text-align: center;
    padding: 3px;
    width: 16.6%;
}

span.flag-span {
    margin-right: 5px;
}

div#statusHedgeTableAlert {
    border: #75787B 1px solid;
    border-radius: 5px;
    background-color: #75787B26;
    color: #75787B;
    text-align: center;

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
    padding: 10px;
    width: 50%;
    margin: 10px auto 10px auto;
}
div.AdminInterface {
}

div#AdminInterfaceContainer {
    background: white;
    margin: 5px;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 20px;
}

table.UserManagementTable td {
    border: 1px black solid;
}
