@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
}

body {
    background-color: #E8EAE9;
}

.App {
    background-color: #E8EAE9;
}

main {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 80px;

    display: grid; 
    grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr; 
    grid-template-rows: 0.05fr 1fr; 
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
}

h1#ContentTitle {
    grid-area: 1 / 2 / 2 / 12;
}

#Content {
    grid-area: 2 / 2 / 3 / 12;
}